import React from 'react'
import { motion } from 'framer-motion';
import ResumeCard from './ResumeCard';

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2002 - Todate</p>
          <h2 className="text-3xl md:text-4xl font-bold">Development Pricing</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title=" Starter Website"
            subTitle="Ideal for: Small businesses or individuals needing a basic online presence."
            result="Price: $ 500 - $3,000+"
            des="What's Included:
            Up to 5 static web pages showcasing your brand and services.
            Responsive design for optimal viewing on all devices (phones, tablets, desktops).
            User-friendly interface for a smooth visitor experience.
            Easy contact form for inquiries and leads.
            Search engine optimization (SEO) best practices for better online visibility."
          />
          <ResumeCard
            title=" Business Website"
            subTitle="Ideal for: Businesses with a growing online presence requiring more features."
            result="Price: $ 2,000 - $10,000+"
            des="What's Included:
            Up to 10 custom web pages tailored to your specific needs.
            Responsive design for optimal viewing on all devices.
            Powerful Content Management System (CMS)  for easy updates.
            E-commerce functionality (optional, add additional cost) to sell products online.
            Custom forms and integrations for lead capture or appointment booking.
            Advanced SEO optimization strategies to attract more qualified traffic."
          />
          <ResumeCard
            title=" Enterprise Website"
            subTitle="Ideal for: Complex projects requiring unique functionalities and integrations."
            result="Price:$ 10,000 - 150,000+ "
            des="What's Included:
            A custom-designed website built to your exact specifications.
            Responsive design for seamless viewing on all devices.
            Advanced CMS with functionalities tailored to your business.
            Complex integrations with third-party applications (e.g., payment gateways, CRMs).
            Comprehensive security features to protect your data and user information.
            Ongoing maintenance and support to keep your website running smoothly (optional, add additional cost)."
          />
        </div>
      </div>
      {/* part Two */}

      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2020 - to date</p>
          <h2 className="text-3xl md:text-4xl font-bold">cybersecurity pricing</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title=" Security Scan"
            subTitle="Ideal for: Identifying potential security vulnerabilities in your website or application."
            result="Price: $ $500 - $2,000+"
            des="What's Included:
            Automated vulnerability scanning to detect potential weaknesses.
            Manual penetration testing (limited scope) to simulate real-world attacks.
            Detailed security risk assessment report outlining vulnerabilities and potential impacts.
            Recommendations for patching identified vulnerabilities to strengthen your defenses."
          />
          <ResumeCard
            title="Advanced Security Assessment & Pen Testing"
            subTitle="Ideal for: A more thorough evaluation of your security posture and identifying exploitable weaknesses."
            result="Price:$ 2,000 - $10,000+
            "
            des="What's Included:
            Comprehensive vulnerability scanning for a wider range of threats.
            In-depth manual penetration testing to uncover deeper vulnerabilities.
            Detailed security risk assessment report with prioritized vulnerabilities and remediation strategies.
            Post-penetration testing consultation to discuss findings and action plans."
          />
          <ResumeCard
            title="Continuous Security Shield"
            subTitle="Ideal for: Proactive protection against cyber threats and ongoing security monitoring."
            result="Price: $ 1,000+ per month "
            des="What's Included:
            24/7 security monitoring for potential threats and suspicious activity.
            Real-time event notifications and incident response to contain threats quickly.
            Regular vulnerability scanning and patching to keep your defenses up-to-date.
            Security best practice recommendations to enhance your overall security posture.
            Optional: Ongoing security awareness training for your employees to identify and avoid phishing attempts and social engineering attacks."
          />
        </div>
      </div>
    </motion.div>
  );
}

export default Education