import React, { useState, useEffect } from 'react';
import Title from '../layouts/Title';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import ProjectsCard from './ProjectsCard';

// Initialize Firebase
const db = getFirestore();

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'projects'));
        const projectsData = querySnapshot.docs.map(doc => doc.data());
        setProjects(projectsData);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <section id="projects" className="w-full py-20 border-b-[1px] border-b-black">
      <div className="flex justify-center items-center text-center">
        <Title title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK" des="My Projects" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        {projects.map((project, index) => (
          <ProjectsCard
            key={index}
            title={project.title}
            des={project.description}
            src={project.imageUrl}
            websiteUrl={project.websiteUrl}
          />
        ))}
      </div>
    </section>
  );
};

export default Projects;
