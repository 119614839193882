import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { FiMenu } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { FaWhatsapp, FaTwitter, FaEnvelope } from 'react-icons/fa';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, onSnapshot, limit } from 'firebase/firestore';
import { navLinksdata } from '../../constants';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [logoURL, setLogoURL] = useState('');

  // Firebase configuration
  const firebaseConfig = {
    // Your Firebase config object goes here
    apiKey: "AIzaSyBYTpbVzSOcx84zrAOFiUv55eLTqljn-bE",
    authDomain: "robpro-821e2.firebaseapp.com",
    projectId: "robpro-821e2",
    storageBucket: "robpro-821e2.appspot.com",
    messagingSenderId: "1093182499524",
    appId: "1:1093182499524:web:c34702407895fe5ed8b791",
    measurementId: "G-DBBLKP2E80"
  };

  // Initialize Firebase app
  const firebaseApp = initializeApp(firebaseConfig);

  useEffect(() => {
    const fetchLogoURL = async () => {
      try {
        const firestore = getFirestore(firebaseApp);
        const logosQuery = query(collection(firestore, 'logos'), limit(1));
        const unsubscribe = onSnapshot(logosQuery, (snapshot) => {
          snapshot.forEach((doc) => {
            const logoData = doc.data();
            setLogoURL(logoData.imageUrl);
          });
        });
        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchLogoURL();
  }, [firebaseApp]);

  return (
    <div className="w-full h-24 sticky top-0 z-50 bg-bodyColor mx-auto flex justify-between items-center font-titleFont border-b-[1px] border-b-gray-600">
      <div>
        <img src={logoURL} alt="logo" style={{ width: '100px', height: '100px' }} />
      </div>
      <div>
        <ul className="hidden mdl:inline-flex items-center gap-6 lg:gap-10">
          {navLinksdata.map(({ _id, title, link }) => (
            <li
              className="text-base font-normal text-gray-400 tracking-wide cursor-pointer hover:text-designColor duration-300"
              key={_id}
            >
              <Link
                activeClass="active"
                to={link}
                spy={true}
                smooth={true}
                offset={-70}
                duration={600}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
        <span
          onClick={() => setShowMenu(!showMenu)}
          className="text-xl mdl:hidden bg-black w-10 h-10 inline-flex items-center justify-center rounded-full text-designColor cursor-pointer"
        >
          <FiMenu />
        </span>
        {showMenu && (
          <div className="w-[80%] h-screen overflow-scroll absolute top-0 left-0 bg-gray-900 p-4 scrollbar-hide">
            <div className="flex flex-col gap-8 py-2 relative">
              <div>
                <img className="w-32" src={logoURL} alt="logo" />
                <p className="text-sm text-gray-400 mt-2">
                  Cybersecurity and ethical hacking.
                </p>
              </div>
              <ul className="flex flex-col gap-4">
                {navLinksdata.map((item) => (
                  <li
                    key={item._id}
                    className="text-base font-normal text-gray-400 tracking-wide cursor-pointer hover:text-designColor duration-300"
                  >
                    <Link
                      onClick={() => setShowMenu(false)}
                      activeClass="active"
                      to={item.link}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={600}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="flex flex-col gap-4">
                <h2 className="text-base uppercase font-titleFont mb-4">
                  Find me in
                </h2>
                <div className="flex gap-4">
      <a href="https://twitter.com/robpro888" target="_blank" rel="noopener noreferrer" className="bannerIcon">
        <FaTwitter />
      </a>
      <a href="https://wa.me/256705602275" target="_blank" rel="noopener noreferrer" className="bannerIcon">
        <FaWhatsapp />
      </a>
      <a href="mailto:robpro888@gmail.com" target="_blank" rel="noopener noreferrer" className="bannerIcon">
        <FaEnvelope />
      </a>
    </div>
              </div>
              <span
                onClick={() => setShowMenu(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-designColor duration-300 text-2xl cursor-pointer"
              >
                <MdClose />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
