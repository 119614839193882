import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Achievement = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex gap-20"
    >
      <div>
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2020 - Present</p>
          <h2 className="text-4xl font-bold"> Cybersecurity </h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title=" Enhancement Project Resulting in Zero Security Incidents"
            subTitle="Spearheaded a comprehensive security enhancement initiative aimed at fortifying the organization's cyber defenses."
            result="Success"
            des="Achieved a significant milestone of zero security incidents or breaches for a continuous period of 12 months, showcasing the effectiveness of the implemented measures and the team's dedication to cybersecurity excellence."
          />
          <ResumeCard
            title="Top Performer in Cybersecurity"
            subTitle="Demonstrated exceptional skills in areas such as ethical hacking, digital forensics, or incident response, surpassing competitors and solving complex cybersecurity challenges."
            result="Success"
            des="This achievement highlights your technical prowess, problem-solving abilities, and competitive spirit in the cybersecurity domain, earning you respect and recognition within the industry."
          />
          <ResumeCard
            title=" Industry-Leading Research Publication in Cybersecurity"
            subTitle="Made significant contributions to the project, such as conducting experiments, analyzing data, or developing innovative solutions."
            result="Success"
            des="Published the research findings in a reputable cybersecurity journal or presented them at a major conference, contributing valuable insights to the cybersecurity community and establishing yourself as a thought leader in the field."
          />
        </div>
      </div>
      <div>
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2020 - Present</p>
          <h2 className="text-4xl font-bold">Development</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Mission-Critical Software Project"
            subTitle="Demonstrated exceptional project management skills, overseeing the entire development lifecycle from requirements gathering to deployment."
            result="Success"
            des="Delivered the project on time and within budget, meeting or exceeding stakeholder expectations and significantly contributing to the organization's success."
          />
          <ResumeCard
            title="Revenue"
            subTitle="Leveraged creativity and technical expertise to identify opportunities for improvement and address user needs or pain points effectively."
            result="Success"
            des="Monitored and analyzed key performance indicators (KPIs) to measure the impact of the feature/product enhancement and demonstrated tangible results in driving business growth."
          />
          <ResumeCard
            title="Wide Adoption and Impac"
            subTitle="Demonstrated expertise in coding, problem-solving, and collaboration by actively participating in project development, submitting code contributions, and engaging with the project community."
            result="Success"
            des="Received recognition or praise from project maintainers or fellow contributors for your valuable contributions, establishing yourself as a respected member of the open-source community."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Achievement;
