import { AiFillAlert } from "react-icons/ai";
import { FaDatabase, FaRadiation} from "react-icons/fa";
import { Si1Password ,SiAntdesign } from "react-icons/si";

// Features Data
export const featuresData = [
  {
    id: 1,
    title: "Full-Stack Development",
    des: "Proficient in both front-end (HTML, CSS, JavaScript, React.js) and back-end (Node.js, Express.js, Python, Django, php) technologies.",
  },
  {
    id: 2,
    icon: <AiFillAlert />,
    title: "Cybersecurity",
    des: "Knowledgeable in various security concepts, including encryption, authentication, access control, and secure coding practices.",
  },
  {
    id: 3,
    icon: <Si1Password />,
    title: "Ethical Hacking",
    des: "Capable of performing ethical hacking exercises to assess and enhance the security posture of applications and infrastructure.",
  },
  {
    id: 4,
    icon: <FaDatabase />,
    title: "Database Management",
    des: "Experienced in working with SQL and NoSQL databases such as FireStore, MongoDB, PostgreSQL, and MySQL.",
  },
  {
    id: 5,
    icon: <SiAntdesign />,
    title: "DevOps",
    des: "Capable of implementing continuous integration and continuous deployment (CI/CD) pipelines.",
  },
  {
    id: 6,
    icon: <FaRadiation />,
    title: "Problem-Solving",
    des: "Strong analytical and problem-solving skills, with a knack for troubleshooting complex issues.",
  },
];
