import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app'; // Import Firebase app initialization
import { getFirestore, collection, query, limit, getDocs } from 'firebase/firestore'; // Import Firestore functions

const RightBanner = () => {
  const [imageUrl, setImageUrl] = useState('');
  const firebaseConfig = {
    // Your Firebase config object
    apiKey: "AIzaSyBYTpbVzSOcx84zrAOFiUv55eLTqljn-bE",
    authDomain: "robpro-821e2.firebaseapp.com",
    projectId: "robpro-821e2",
    storageBucket: "robpro-821e2.appspot.com",
    messagingSenderId: "1093182499524",
    appId: "1:1093182499524:web:c34702407895fe5ed8b791",
    measurementId: "G-DBBLKP2E80"
  };
  const firebaseApp = initializeApp(firebaseConfig); // Initialize Firebase app

  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        const firestore = getFirestore(firebaseApp); // Get Firestore instance
        const bannerQuery = query(collection(firestore, 'banners'), limit(1)); // Query for one banner document
        const bannerSnapshot = await getDocs(bannerQuery); // Fetch banner document
        bannerSnapshot.forEach((doc) => {
          const bannerData = doc.data();
          setImageUrl(bannerData.imageUrl);
        });
      } catch (error) {
        console.error('Error fetching image URL:', error);
      }
    };

    fetchImageUrl();
  }, [firebaseApp]);

  return (
    <div className="w-full lgl:w-1/2 flex justify-center items-center relative">
      <img
        className="w-[300px] h-[400px] lgl:w-[500px] lgl:h-[680px] z-10"
        src={imageUrl}
        alt="bannerImg"
      />
      <div className="absolute bottom-0 w-[350px] h-[300px] lgl:w-[500px] lgl:h-[500px] bg-gradient-to-r from-[#1e2024] to-[#202327] shadow-shadowOne flex justify-center items-center"></div>
    </div>
  );
};

export default RightBanner;
