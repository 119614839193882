import React from 'react'
import { FaWhatsapp, FaTwitter, FaEnvelope , FaReact } from "react-icons/fa";
import { SiCplusplus, SiPython, SiNextdotjs, } from "react-icons/si";
const Media = () => {
  return (
    <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me in
          </h2>
          <div className="flex gap-4">
      <a href="https://twitter.com/robpro888" target="_blank" rel="noopener noreferrer" className="bannerIcon">
        <FaTwitter />
      </a>
      <a href="https://wa.me/256705602275" target="_blank" rel="noopener noreferrer" className="bannerIcon">
        <FaWhatsapp />
      </a>
      <a href="mailto:robpro888@gmail.com" target="_blank" rel="noopener noreferrer" className="bannerIcon">
      <FaEnvelope />
      </a>
    </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL ON
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
              <FaReact />
            </span>
            <span className="bannerIcon">
              <SiNextdotjs />
            </span>
            <span className="bannerIcon">
              <SiCplusplus />
            </span>
            <span className="bannerIcon">
              <SiPython />
            </span>
           
          </div>
        </div>
      </div>
  )
}

export default Media