import React from 'react'
import { FaTwitter, FaWhatsapp,FaEnvelope } from "react-icons/fa";
import { contactImg } from "../../assets/index";

const ContactLeft = () => {
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center">
      <img
        className="w-full h-64 object-cover rounded-lg mb-2"
        src={contactImg}
        alt="contactImg"
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-white">RobPro</h3>
        <p className="text-lg font-normal text-gray-400">
        Full-stack Developer
        </p>
        <p className="text-base text-gray-400 tracking-wide">
        "Whether it's developing innovative software solutions or enhancing cybersecurity measures, I'm here to help."
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Phone: <span className="text-lightText">+256 704512188</span>
        </p>
        <p className="text-base text-gray-400 flex items-center gap-2">
          Email: <span className="text-lightText">robpro888@gmail.com</span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">Find me on</h2>
        <div className="flex gap-4">
      <a href="https://twitter.com/robpro888" target="_blank" rel="noopener noreferrer" className="bannerIcon">
        <FaTwitter />
      </a>
      <a href="https://wa.me/256705602275" target="_blank" rel="noopener noreferrer" className="bannerIcon">
        <FaWhatsapp />
      </a>
      <a href="mailto:robpro888@gmail.com" target="_blank" rel="noopener noreferrer" className="bannerIcon">
        <FaEnvelope /></a>
    </div>
      </div>
    </div>
  );
}

export default ContactLeft