import React from "react";
import {motion} from "framer-motion"
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex gap-20"
    >
      <div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2020 - Present</p>
          <h2 className="text-4xl font-bold">Cybersecurity Experience</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Security Analyst"
            subTitle="Freelance - (2019 - Present)"
            result="Uganda"
            des=" Strong understanding of cybersecurity principles, knowledge of various security tools and technologies (e.g., SIEM, IDS/IPS, firewalls), ability to analyze and interpret security logs and alerts, and familiarity with regulatory compliance requirements (e.g., GDPR, HIPAA, PCI-DSS)."
          />
          <ResumeCard
            title="Penetration Tester (Ethical Hacker):"
            subTitle="Freelance - (2019 - Present)"
            result="Uganda"
            des="Proficiency in various penetration testing tools and techniques, such as vulnerability scanning, exploitation, and social engineering. Strong understanding of common security vulnerabilities (e.g., SQL injection, XSS, CSRF) and ability to simulate real-world attack scenarios."
          />
          <ResumeCard
            title="Security Consultant"
            subTitle="Freelance - (2020 - Present)"
            result="Uganda"
            des=" Extensive knowledge of cybersecurity frameworks (e.g., NIST Cybersecurity Framework, ISO/IEC 27001), experience with conducting security audits and assessments, excellent communication and presentation skills to convey complex technical concepts to non-technical stakeholders, and the ability to tailor security solutions to meet specific business requirements."
          />
        </div>
      </div>
      <div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2020 - Present</p>
          <h2 className="text-4xl font-bold">Development Experience</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Blockchain Developmen"
            subTitle="Freelance (2020 - Present)"
            result="Uganda"
            des=" Expertise in developing secure and scalable blockchain solutions for various use cases, such as supply chain management, finance, healthcare, and decentralized finance (DeFi), is highly valued."
          />
          <ResumeCard
            title="Internet of Things (IoT) Development"
            subTitle="Freelance (2020 - Present)"
            result="Uganda"
            des="Expertise in designing and implementing IoT architectures, edge computing solutions, device management, security, and data analytics for IoT systems is highly valued."
          />
          <ResumeCard
            title="Cybersecurity Development"
            subTitle="Freelance (2020 - Present)"
            result="Uganda"
            des="Expertise in developing security solutions such as intrusion detection systems (IDS), encryption libraries, security assessment tools, and secure APIs can be highly valuable in combating cyber threats."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
